<template>
  <v-card outlined>
    <v-list>
      <v-list-item
        v-for="(empresa, index) in empresas"
        :key="`empresa-${index}`"
      >
        <v-list-item-icon>
          <v-icon color="primary">mdi-office-building</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <router-link class="link" :to="`empresa/${empresa._id}`">{{
              empresa.nombre
            }}</router-link>
          </v-list-item-title>
          <v-list-item-subtitle>{{ empresa.bucket }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["empresas"],
  computed: {
    ...mapGetters(["thumbsURL"]),
  },
  data: () => ({
    loading: false,
  }),
};
</script>
