<template>
  <v-container>
    <v-card>
      <v-toolbar
        color="primary"
        dense
        flat
        dark
        class="text-h6"
        :loading="loading"
        >Empresas
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="agregarEmpresa = true">
              <v-icon>mdi-playlist-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar empresa</span>
        </v-tooltip>
      </v-toolbar>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="secondary"
      ></v-progress-linear>
      <v-container>
        <listadoEmpresasComponent :empresas="empresas" />
      </v-container>
    </v-card>
    <agregarEmpresaComponent
      v-if="agregarEmpresa"
      :mostrar="agregarEmpresa"
      @cancelar="agregarEmpresa = false"
      @empresaAgregada="empresaAgregada"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { getListadoEmpresas } from "./empresas.service";

import agregarEmpresaComponent from "./agregarEmpresa.vue";
import listadoEmpresasComponent from "./listadoEmpresas.vue";

export default {
  name: "ListadoEmpresas",
  components: {
    listadoEmpresasComponent,
    agregarEmpresaComponent,
  },
  computed: {
    ...mapGetters(["sessionToken"]),
  },
  data() {
    return {
      loading: false,
      agregarEmpresa: false,
      empresas: [],
    };
  },
  watch: {
    sessionToken(value) {
      if (value) this.cargarEmpresas();
    },
  },
  async mounted() {
    if (this.sessionToken) this.cargarEmpresas();
  },
  methods: {
    async cargarEmpresas() {
      try {
        this.loading = true;
        const serverResponse = await getListadoEmpresas();
        this.loading = false;
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        else {
          this.empresas = serverResponse.empresas;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    empresaAgregada(empresa) {
      this.empresas.push(empresa);
      this.agregarEmpresa = false;
    }
  },
};
</script>
