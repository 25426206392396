<template>
  <v-dialog v-model="mostrar" persistent max-width="400">
    <v-card :loading="loading">
      <v-toolbar color="primary" dense flat dark class="text-h6"
        >Agregar empresa</v-toolbar
      >
      <v-form v-on:submit.prevent>
        <v-card-text>
          <v-text-field
            label="Nombre:"
            v-model="nombre"
            outlined
            dense
            :error-messages="nombreErrors"
            @input="$v.nombre.$touch()"
            @blur="$v.nombre.$touch()"
          ></v-text-field>
          <v-text-field
            label="Nombre corto"
            v-model="nombreCorto"
            maxlength="20"
            outlined
            dense
            :error-messages="nombreCortoErrors"
            @input="$v.nombreCorto.$touch()"
            @blur="$v.nombreCorto.$touch()"
          ></v-text-field>
          <v-text-field
            label="Folder"
            v-model="folder"
            maxlength="20"
            outlined
            dense
            :error-messages="folderErrors"
            @input="$v.folder.$touch()"
            @blur="$v.folder.$touch()"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined @click="$emit('cancelar')"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            small
            dark
            :class="{ 'disable-btn': disableBtn }"
            @click="agregarEmpresa()"
            >Agregar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { agregarEmpresaService } from "./empresas.service";

export default {
  props: ["mostrar"],
  mixins: [validationMixin],
  computed: {
    nombreErrors() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.minLength && errors.push("Mínimo 5 caracteres.");
      !this.$v.nombre.required && errors.push("Campo requerido.");
      return errors;
    },
    nombreCortoErrors() {
      const errors = [];
      if (!this.$v.nombreCorto.$dirty) return errors;
      !this.$v.nombreCorto.minLength && errors.push("Mínimo 5 caracteres.");
      !this.$v.nombreCorto.required && errors.push("Campo requerido.");
      return errors;
    },
    folderErrors() {
      const errors = [];
      if (!this.$v.folder.$dirty) return errors;
      !this.$v.folder.minLength && errors.push("Mínimo 5 caracteres.");
      !this.$v.folder.required && errors.push("Campo requerido.");
      return errors;
    },
    disableBtn() {
      if (this.nombreErrors.length > 0) return true;
      if (this.nombreCortoErrors.length > 0) return true;
      if (this.folderErrors.length > 0) return true;
      return false;
    },
  },
  validations: {
    nombre: { required, minLength: minLength(5) },
    nombreCorto: { required, minLength: minLength(5) },
    folder: { required, minLength: minLength(5) },
  },
  data() {
    return {
      loading: false,
      nombre: null,
      nombreCorto: null,
      folder: null,
      database: null,
    };
  },
  methods: {
    async agregarEmpresa() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const sendData = {
            nombre: this.nombre,
            nombreCorto: this.nombreCorto,
            folder: this.folder,
          };
          this.loading = true;
          const serverResponse = await agregarEmpresaService(sendData);
          this.loading = false;
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else this.$emit("empresaAgregada", serverResponse.empresa);
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
  },
};
</script>
