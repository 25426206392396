import { getServerRequest, putServerRequest, patchServerRequest, deleteServerRequest } from '@/components/globals/services/serverRequest.service';

import { store } from '../../store/store';

const httpURL = store.getters.httpURL;

export const getListadoEmpresas = async() => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/empresas`;
    return await getServerRequest(url, config);
}

export const getDatosEmpresa = async(idEmpresa) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/empresa/datos/${idEmpresa}`;
    return await getServerRequest(url, config);
}

export const getAdministradoresEmpresa = async(idEmpresa) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/empresa/admins/${idEmpresa}`;
    return await getServerRequest(url, config);
}

export const modificarActivoUsuarioService = async(data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const action = data.activo ? 'activar' : 'desactivar';
    const url = `${httpURL}/empresa/admin/${action}/${data.idEmpresa}/${data.idUsuario}`;
    return await patchServerRequest(url, { activo: data.activo }, config);
}

export const agregarEmpresaService = async(data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/empresa`;
    return await putServerRequest(url, data, config);
}

export const activarEmpresaService = async(idEmpresa) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/empresa/activar/${idEmpresa}`;
    return await patchServerRequest(url, {}, config);
}

export const agregarUsuarioService = async(idEmpresa, idUsuario) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/empresa/administrador/${idEmpresa}/${idUsuario}`;
    return await putServerRequest(url, {}, config);
}

export const quitarAdministradorService = async(idUsuarioEmpresa) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/empresa/administrador/${idUsuarioEmpresa}`;
    return await deleteServerRequest(url, config);
}